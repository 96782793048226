//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { getLanguageCode } from '@/i18n';
import { i18n } from '@/i18n';
// import { Container, Draggable } from "vue-smooth-dnd";

// import AddNewPlaceFormStep1 from '@/modules/place/components/add-new-place-form-step1.vue';
// import AddNewPlaceFormStep2 from '@/modules/place/components/add-new-place-form-step2.vue';
import PlaceFormStep1 from '@/modules/place/components/place-form-step-1.vue';
import PlaceFormStep2 from '@/modules/place/components/place-form-step-2.vue';

import { PlaceModel } from '@/modules/place/place-model';
import { FormSchema } from '@/shared/form/form-schema';

import firebase from 'firebase/app';
import 'firebase/storage';

const { fields } = PlaceModel;
const formSchema = new FormSchema([
    fields.id,
    fields.placeName,
    fields.description,
    fields.placePictures,
    fields.cityId,
    fields.placeType,
    fields.address,
    fields.lat,
    fields.lng,
    fields.radius,
    fields.strokeColor,
    fields.strokeWidth,
    fields.fillColor,
    fields.notifications

  // fields.notificationImage,
  // fields.notificationName,
  // fields.notificationLanguage,
  // fields.notificationTitle,
  // fields.notificationText,


]);

export default {
  name: 'app-add-new-place-form',
  props: ['id'],

  components: {
    [PlaceFormStep1.name]: PlaceFormStep1,
    [PlaceFormStep2.name]: PlaceFormStep2
    // [AddNewPlaceFormStep1.name]: AddNewPlaceFormStep1,
    // [AddNewPlaceFormStep2.name]: AddNewPlaceFormStep2,
  },

  data() {
    return {
      step: 1,
      language: getLanguageCode(),
      rules: formSchema.rules(),
      model: null,
      placePictures: [],
      recordId: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      record: 'place/form/record',
      findLoading: 'place/form/findLoading',
      saveLoading: 'place/form/saveLoading',
      is_screen_xs: 'layout/is_screen_xs',
      citiesRows: 'city/list/rows',
      loadingCities: 'city/list/loading',
      placesRows: 'place/list/rows',
      loadingPlaces: 'place/list/loading',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    isEditing() {
      return !!this.id;
    },
    fields() {
      return fields;
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    cities() {
      return this.citiesRows.map((item) => {
        return {
          value: item.id,
          label: item.name[this.language],
        };
      });
    },
    // placesList() {
    //   return this.placesRows
    // },
  },

  async created() {
    debugger
    this.model = formSchema.initialValues(this.record);
    debugger
    this.model.placeName = this.model.placeName || { en: undefined, ar: undefined }
    this.model.description = this.model.description || { en: undefined, ar: undefined }
    this.model.address = this.model.address || { en: undefined, ar: undefined }
    this.model.lat =  21.485811,
    this.model.lng =  39.19250479999999
    this.model.fillColor = '#247BA0';
    this.model.strokeColor = '#247BA0';
    this.model.strokeWidth = 10;
    this.model.radius = 600;
    this.model.notifications =  {
      en: {
        title: undefined,
        description: undefined
      } ,
      ar: {
        title: undefined,
        description: undefined
      }, 
      fr: {
        title: undefined,
        description: undefined
      }, 
      es: {
        title: undefined,
        description: undefined
      }}
    this.model.status = "pending"
   
  },
  async mounted() {
    //await this.doFetchCities()
  },

  methods: {
    ...mapActions({
      doCreate: 'place/form/doCreate',
      doUpdate: 'place/form/doUpdate'

    }),
    i18n(key, args) {
      return i18n(key, args);
      // return this.$t(key, args);
    },
   
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    filterFn(val, update) {
      if (val === '') {
        update(() => {
          this.citiesOptions = this.cities;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.citiesOptions = this.cities.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1,
        );
      });
    },

    prev() {
      this.step--;
    },
    async goToNextPage(){
      // this.$refs.stepper.next()
      console.log(this.model);
      console.log(this.placePictures);
      // debugger
      this.step++;
      
      // console.log(value);
      // console.log('step no => ', value.stepNo);
      // console.log('value payload', value.payload);
      // if(value.stepNo == 1){
      //   this.duration = value.payload
      // }
      // if(value.stepNo == 2){
      //   this.programList = value.payload
      // }
    },
    next() {
      if (this.step == 2) {
        return;
      }
      this.step++;
    },
    async doSubmit() {
      console.log('model => ',this.model);
      console.log(this.placePictures);
      this.loading = true
      const record = await this.doCreate(this.model)
      this.recordId = record.id
      console.log(this.recordId);
      
      if(this.placePictures){
       const placePictures = await this.uploadImageCallBack()
       this.model.placePictures = placePictures
       console.log(this.model);       
       await this.doUpdate({id: this.recordId, values: this.model})
      }
      this.loading = false
      this.$router.push('place')
    },

  async uploadImageCallBack(){
  const uploads = [];
  const promises = [];
  const ref = firebase.storage().ref();
  const path = `saryahCompany/company/place/${this.recordId}`
  this.placePictures.forEach(file => {    
    const fullPath = `${path}/${file.name}`;
    const task = ref.child(fullPath)
    const uploadPromise = task.put(file).then(async () => {
      const downloadURL = await task.getDownloadURL();
      uploads.push({
        name: file.name,
        publicUrl: downloadURL
      });
      console.log('in push');
    });
    promises.push(uploadPromise);
    
  });

  await Promise.all(promises); // Wait for all promises to complete
  console.log(uploads);
  return uploads;
},

    // async uploadPlacePictures(path, request) {
    //         console.log(request);            
    //         const ref = firebase.storage().ref();
    //         const fullPath = `${path}/${request.name}`;
    //         const task = ref.child(fullPath).put(request);

    //         await task.on(
    //         firebase.storage.TaskEvent.STATE_CHANGED,
    //         (snapshot) => {
    //             const percent =
    //             (snapshot.bytesTransferred /
    //                 snapshot.totalBytes) *
    //             100;
    //             console.log('precent', percent);
    //         },
    //         (error) => {
    //             // request.onError(error);
    //             console.log(error);
    //         },
    //         () => {
    //             task.snapshot.ref
    //             .getDownloadURL()
    //             .then(async (url) => {
    //                 console.log(url);
    //                 // this.model.logo.publicUrl = url
    //                 // this.model.logo.name = request.name
    //                 // console.log(this.model.logo); 
    //                 this.model.placePictures = [
    //                   {
    //                     publicUrl: undefined,
    //                     name: undefined
    //                   },
    //                 ]
    //                 this.model.placePictures[0].publicUrl = url
    //                 this.model.placePictures[0].name = request.name
    //                 console.log(this.model);
    //                 debugger                    
    //                 await this.doUpdate({id: this.recordId, values: this.model })
    //                 // this.uploadCommercialRegister(`saryahCompany/company/${this.id}/commercial register`, this.commercialRegister)
    //             })
    //             .catch((error) => {
    //                 console.log('error', error);
    //             });
    //         },
    //         );
    //     },
  },
};
