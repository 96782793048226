//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { PlaceModel } from '@/modules/place/place-model';
import axios from 'axios';
// import { getLanguageCode } from '@/i18n';
import { i18n } from '@/vueI18n'
import PlaceFormLocation from '@/modules/place/components/place-form-location.vue'


const { fields } = PlaceModel;
const formSchema = new FormSchema([
  fields.id,
  fields.placeName,
  fields.city,
  fields.cityId,
  fields.lat,
  fields.lng,
  fields.address,

  fields.radius,
  fields.fillColor,
  fields.strokeWidth,
  fields.strokeColor,

  fields.placeType,
  fields.description,
  fields.placePictures,
]);

export default {
//   name: 'app-add-new-place-form-step1',
  name: 'app-place-form-step-1',
  props: {
    value: {
      type: Object,
      required: true,
    },
    placePictures:{
      type: Array,
      required: true
    },
    loading:{
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
    //   language: getLanguageCode(),
      rules: formSchema.rules(),
      model: null,
      center: { lat: 21.485811, lng: 39.19250479999999 },
     
      autocomplete: '',
      colors: {
        hex: '#247BA0', 
      },
      Scolors: {
        hex: '#247BA0',
      },
      colorValue: '#247BA0',
      defaultColor: '#247BA0',
      radiusValue: 600,

      address: {},
      citiesOptions: [],
      displayPicker: false,
      displaySPicker: false,

      currentPlace: null,
      places: [],
      fillColor: '#247BA0',
      strokeColor: '#247BA0',

      firstImg: null,
      secondImg: null,
      thirdImg: null,
      fourthImg: null,
      fifthImg: null

    };
  },
  components: {
    [PlaceFormLocation.name]: PlaceFormLocation
  },

  computed: {
    ...mapGetters({
      record: 'place/form/record',
      findLoading: 'place/form/findLoading',
      saveLoading: 'place/form/saveLoading',
      citiesRows: 'city/list/rows',  
      loadingCities: 'city/list/loading',  
      currentLanguageCode: 'layout/currentLanguageCode',
      is_screen_xs: 'layout/is_screen_xs'
    }),
    lat(){
      return this.value.lat
    },
    isEditing() {
      return !!this.id;
    },
    isRTL() {
        return i18n.locale == "ar"
      // return localStorage.getItem('language') == 'ar';
    //   return this.currentLanguageCode == 'ar';
    },
    lang(){
        return i18n.locale
    },
    fields() {
      // console.log(fields);
      // debugger
      return fields;
    },
    cities() {
      return this.citiesRows.map(item => {
        return {
          value: item.id,
          // label: item.name[this.lang],
          label: this.isRTL ? item.name['ar'] : item.name['en']
        }
      })
    },
    // placesType() {
    //   return this.placesTypeRows.map(item => {
    //     return {
    //       value: item.id,
    //       label: item.name[this.language],
    //       icon: item.icon,
    //     }
    //   })
    // },
    placeType() {
      return fields.placeType.options
    },
    
  },

 
  
  
  async mounted() {
    // this.$nextTick(() => {
    //   this.$refs.map
    //     .$gmapApiPromiseLazy()
    //     .then(this.loadControls);
    // });
    // this.geolocate();
    //---------------------
   
    //---------------------
    
    this.getUserCurrentLocation()
   
    await this.doFetchCities()
     
    
  },

  methods: {
    ...mapActions({
      doFind: 'place/form/doFind',
      doNew: 'place/form/doNew',
      doUpdate: 'place/form/doUpdate',
      doCreate: 'place/form/doCreate',
      setPlaces: 'place/form/setPlaces',
      doFetchCities: 'city/list/doFetch',
      
    }),
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
    filterFn (val, update) {
      if (val === '') {
        update(() => {
          this.citiesOptions = this.cities
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.citiesOptions = this.cities.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },

    getFirstImg(value){
      this.firstImg = value
      
    },
    getSecondImg(value){
      this.secondImg = value
      
    },
    getThirdImg(value){
      this.thirdImg = value
      
    },
    getFourthImg(value){
      this.fourthImg = value      
    },
    getFifthImg(value){
      this.fifthImg = value
    },
    // ........................................................................................................................
    // loadControls function specified in create add my current location button and bind all style
    // to this button , and add listener to that button when clicked to call the blew child
    // that call another function called getCurrentPosition that return the current location
    // finally set the position of this button on  RIGHT_BOTTOM as a custom element
    loadControls(map) {
      var controlDiv = document.createElement('div');
      var firstChild = document.createElement('button');
      firstChild.style.backgroundColor = '#fff';
      firstChild.style.border = 'none';
      firstChild.style.outline = 'none';
      firstChild.style.width = '28px';
      firstChild.style.height = '28px';
      firstChild.style.borderRadius = '2px';
      firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
      firstChild.style.cursor = 'pointer';
      firstChild.style.marginRight = '10px';
      firstChild.style.padding = '0px';
      firstChild.title = 'Your Location';
      controlDiv.appendChild(firstChild);
      var secondChild = document.createElement('div');
      secondChild.style.margin = '5px';
      secondChild.style.width = '18px';
      secondChild.style.height = '18px';
      secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)';
      secondChild.style.backgroundSize = '180px 18px';
      secondChild.style.backgroundPosition = '0px 0px';
      secondChild.style.backgroundRepeat = 'no-repeat';
      secondChild.id = 'you_location_img';
      firstChild.appendChild(secondChild);
      window.google.maps.event.addListener(
        this.$refs.map.$mapObject,
        'center_changed',
        function() {
          secondChild.style['background-position'] = '0 0';
        },
      );
      var ref = this;
      firstChild.addEventListener('click', function() {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            let latlng = new window.google.maps.LatLng(
              parseFloat(position.coords.latitude),
              parseFloat(position.coords.longitude),
            );
            ref.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
          },
        );
      });
      controlDiv.index = 1;
      this.$refs.map.$mapObject.controls[
        window.google.maps.ControlPosition.RIGHT_BOTTOM
      ].push(controlDiv);
    },

    // Re initialize all records (reseted records )
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },

    async doSubmit() {
      this.value.strokeWidth = parseFloat(this.value.strokeWidth)
      this.value.lng = parseFloat(this.value.lng)
      this.value.lat = parseFloat(this.value.lat)
      this.value.radius = parseFloat(this.value.radius)
      if(this.firstImg){
        this.placePictures.push(this.firstImg)
      }
      if(this.secondImg){
        this.placePictures.push(this.secondImg)
      }
      if(this.thirdImg){
        this.placePictures.push(this.thirdImg)
      }
      if(this.fourthImg){
        this.placePictures.push(this.fourthImg)
      }
      if(this.fifthImg){
        this.placePictures.push(this.fifthImg)
      }
      this.$emit('next', this.placePictures)

      // this.$router.push(`/place/notification`)
     
    },

    //  setPlcae function take place and assigned it to currentPlace
    //  set address of that place with street , city and country

    setPlace(place) {
      this.currentPlace = place;
      this.address = {
        street: place.address_components[0].long_name,
        city: place.address_components[0].long_name,
        country: place.address_components[0].long_name,
      };
    },

    // addMarker function check first if CurrentPlace if yes
    // set marker to that place
    // push this place to array places which consist of lists of all places
    // then assign center with lat and lng to center of that marker
    // finally set current place to null value
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };

        this.places.push(this.currentPlace);
        this.value.lat = marker.lat
        this.value.lng = marker.lng
        this.currentPlace = null
      }
    },

    // geolocate function call get current postion and set center to that position lat and lng
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        },
      );
    },

    // updateCoordinates take location as parameter and set center lat and lng to that location
    updateCoordinates(location) {
      this.value.lat = 
        location.latLng.lat();
      this.value.lng = 
        location.latLng.lng();
    },
    setColor(color) {
      this.updateColors(color);
      this.colorValue = color;
    },
    setSColor(color) {
      this.updateSColors(color);
      this.strokeColor = color;
    },

    updateColors(color) {
      if (color.slice(0, 1) == '#') {
        this.colors = {
          hex: color,
        };
      } else if (color.slice(0, 4) == 'rgba') {
        var rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(','),
          hex = '#' + ( (1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) +  parseInt(rgba[2]) ).toString(16).slice(1);
        this.colors = {
          hex: hex,
          a: rgba[3],
        };
      }
    },
    updateSColors(color) {
      if (color.slice(0, 1) == '#') {
        this.Scolors = {
          hex: color,
        };
      } else if (color.slice(0, 4) == 'rgba') {
        var rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(','),
          hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
        this.Scolors = {
          hex: hex,
          a: rgba[3],
        };
      }
    },
    showPicker() {
      document.addEventListener('click', this.documentClick);
      this.displayPicker = true;
    },
    showSPicker() {
      document.addEventListener('click', this.documentSClick);
      this.displaySPicker = true;
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick);
      this.displayPicker = false;
    },
    hideSPicker() {
      document.removeEventListener('click', this.documentSClick);
      this.displaySPicker = false;
    },
    togglePicker() {
      this.displayPicker
        ? this.hidePicker()
        : this.showPicker();
    },
    toggleSPicker() {
      this.displaySPicker
        ? this.hideSPicker()
        : this.showSPicker();
    },
    // two
    updateFromSInput() {
      this.updateSColors(this.strokeColor);
    },
    updateFromInput() {
      this.updateColors(this.colorValue);
    },
    // end
    updateFromPicker(color) {
      this.colors = color;
      if (color.rgba.a == 1) {
        this.colorValue = color.hex;
      } else {
        this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
      }
    },
    updateFromSPicker(color) {
      this.Scolors = color;
      if (color.rgba.a == 1) {
        this.strokeColor = color.hex;
      } else {
        this.strokeColor = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
      }
    },
    documentClick(e) {
      var el = this.$refs.colorpicker,
        target = e.target;
      if (el !== target && !el.contains(target)) {
        this.hidePicker();
      }
    },
    documentSClick(e) {
      var el = this.$refs.colorSpicker,
        target = e.target;
      if (el !== target && !el.contains(target)) {
        this.hideSPicker();
      }
    },
    getLocation(){
           
      axios({
            method: 'get',
            url: 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + this.value[fields.lat.name] + ',' + this.value[fields.lng.name] + '&key=AIzaSyDgrVwgswxqsFsvyNVGC7MAPm2xdBtRTHo'

          })
          .then((response) => {
            this.value[fields.address.name]['en'] = response.data.results[1].formatted_address
            console.log(response.data.results[1].formatted_address);
            console.log( ' Getlocation func lat and long >>>' ,this.center.lat, this.center.lng);
            console.log( ' Getlocation func lat and long >>>' ,this.center.lat, this.center.lng);
          })
          .catch((e)=>{
            this.value[fields.address.name]['en'] = ''
          })

      axios({
            method: 'get',
            url: 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + this.value[fields.lat.name] + ',' + this.value[fields.lng.name] + '&key=AIzaSyDgrVwgswxqsFsvyNVGC7MAPm2xdBtRTHo&language=ar'

          })
          .then((response) => {
            this.value[fields.address.name]['ar'] = response.data.results[1].formatted_address
            console.log(response.data.results[1].formatted_address);
            
            console.log( ' Getlocation func lat and long >>>' ,this.center.lat, this.center.lng);
            console.log( ' Getlocation func lat and long >>>' ,this.center.lat, this.center.lng);
          })
          .catch((e)=>{
            this.value[fields.address.name]['ar'] = ''
          })
          
    },
    getUserCurrentLocation(){
    
      window.navigator.geolocation
       .getCurrentPosition((pos)=>{
         console.log('pos is >>' ,pos.coords.latitude);
          this.value[fields.lat.name] = pos.coords.latitude
          this.value[fields.lng.name] = pos.coords.longitude
          this.value.lat=pos.coords.latitude
          this.value.lng=pos.coords.longitude
          this.getLocation()
          console.log( 'lat and long >>>' ,this.value.lat, this.value.lng);
          
       },(err)=>{
          
          this.value[fields.lat.name] = this.center.lat
          this.value[fields.lng.name] = this.center.lng
          this.getLocation()
       });
       
    }
    
  },
  watch: {
    colorValue(val) {
      if (val) {
        this.updateColors(val);
        this.$emit('input', val);
      }
    },
    lat(){
      this.getLocation()
    },
    strokeColor(val) {
      if (val) {
        this.updateSColors(val);
        this.$emit('input', val);
      }
    },
  },
};
